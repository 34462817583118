<template>
    <div>
      <BasePage>
        <div class="container-fluid d-flex flex-column justify-content-end flex-grow-1 p-0 position-relative" id="app-chatbot" :urlapi="apiUrl">
            <section class="container">
              <div class="row">
                <div class="col-12 col-lg-6 pe-2 d-flex flex-column">
                  <h1 class="text-secondary fw-light text-center fw-lg-light text-lg-start fz-xmedium fz-lg-large px-2 pt-2 pt-lg-2 pt-xxl-4 px-lg-0" style="margin-bottom: 30px"><i>Assistente <b class="fw-bold">Virtual</b></i></h1>
  
                  <div class="flex-grow-1 pb-3 pe-lg-4">
                    <div class="mb-5 chat-rounded-container pe-1">
                      <div class="p-lg-2 p-1 chat-track" ref="scrollContent">
                        <div class="row-pi row p-0 m-0 chat-item" v-for="msg in mensagens" :key="msg.id">
                          <div class="pi-dialog px-1 px-lg-2 d-flex">
                            <div class="img-pi-container">
                              <img class="me-3 img-pi" src="../../../../assets/images/pi-icon.png" alt="" v-if="msg.origem === 1">
                            </div>
                            <div class="chat-balloons-group">
                              <!-- PI TC -->
                              <div class="bg-medium-green chat-balloon mb-2" v-if="msg.origem === 1">
                                <p class="fz-xsmall fz-lg-smedium py-2 px-3 mb-0">
                                  <i>
                                    <span class="text-yellow fw-bold">Pi:</span>
                                    <span v-html="msg.msg"></span>
                                  </i>
                                </p>
                              </div>
                              <!-- CLIENTE RESPONDENDO -->
                              <div class="row-response my-3 row p-0 m-0 chat-item justify-content-end" v-if="msg.origem === 2">
                                <div class="response px-1 px-lg-2 d-flex justify-content-end">
                                  <div class="bg-light-gray chat-balloon mb-2">
                                    <p class="fz-xsmall fz-lg-smedium py-2 px-3 mb-0">
                                      <i>
                                        <span class="text-primary fw-bold">Você:</span>
                                        <span class="text-primary fw-semi-bold">@{{ msg.msg }}</span>
                                      </i>
                                    </p>
                                  </div>
                                </div>
                              </div>
  
                              <!-- INTERAÇÕES -->
                              <div class="d-flex flex-column m-0 p-0">
                                <span v-for="(interacao, index) in msg.interacoes" :key="index">
                                  <!-- BOTÕES -->
                                  <div v-if="interacao.chatbot_inputs_id === 2" class="bg-yellow d-flex align-items-center justify-content-between mx-0 chat-balloon alternative mb-2 overflow-hidden onmouse" @click="linkProxMensagem(interacao.link_mensagens_id, interacao.label)">
                                    <div style="width:40px" class="bg-medium-green text-yellow fw-bold p-2 text-center d-inline-block fz-small fz-lg-smedium">
                                      <i>@{{ setOpcao(index) }}</i>
                                    </div>
                                    <span class="text-alternative lh-sm ps-2 pe-2 fz-xsmall fz-lg-smedium fw-bold py-2 d-flex align-items-center">
                                      <i>@{{ interacao.label }}</i>
                                    </span>
                                  </div>
                                  <!-- TEXT FIELDS - CPF -->
                                  <div v-if="interacao.chatbot_inputs_id === 1" class="bg-yellow d-flex align-items-center justify-content-between mx-0 chat-balloon alternative mb-2 overflow-hidden onmouse">
                                    <div class="bg-yellow d-flex align-items-center justify-content-between mx-0 chat-balloon alternative mb-0 overflow-hidden">
                                      <span class="fw-bold fz-xsmall fz-lg-smedium px-3" style="width: 225px"><i>@{{ interacao.label }}</i></span>
                                      <div style="width:80%" class="bg-medium-green text-yellow fw-bold p-0 me-0 text-center">
                                        <input class="h-100 p-2 fz-xsmall fst-italic text-start fz-lg-smedium w-100 text-white lh-sm border-0 bg-transparent" placeholder="999.999.999-99" type="text" @keyup.enter="handleCPF(interacao.link_mensagens_id)" v-model="cpfCliente" v-mask="'###.###.###-##'">
                                      </div>
                                    </div>
                                  </div>
                                  <!-- TEXT FIELDS - E-MAIL -->
                                  <div v-if="interacao.chatbot_inputs_id === 5" class="bg-yellow d-flex align-items-center justify-content-between mx-0 chat-balloon alternative mb-2 overflow-hidden onmouse">
                                    <div class="bg-yellow d-flex align-items-center justify-content-between mx-0 chat-balloon alternative mb-0 overflow-hidden">
                                      <span class="fw-bold fz-xsmall fz-lg-smedium px-3"><i>@{{ interacao.label }}</i></span>
                                      <div style="width:60%" class="bg-medium-green text-yellow fw-bold p-0 me-0 text-center">
                                        <input class="h-100 p-2 fz-xsmall fst-italic text-start fz-lg-smedium w-100 text-white lh-sm border-0 bg-transparent" placeholder="seuemail@email.com" type="email" @keyup.enter="handleEMAIL()" v-model="emailCliente">
                                      </div>
                                    </div>
                                  </div>
                                  <!-- TEXT FIELDS - VALOR DEVIDO -->
                                  <div v-if="interacao.chatbot_inputs_id === 6" class="bg-yellow d-flex align-items-center justify-content-between mx-0 chat-balloon alternative mb-2 overflow-hidden onmouse">
                                    <div class="bg-yellow d-flex align-items-center justify-content-between mx-0 chat-balloon alternative mb-0 overflow-hidden">
                                      <span class="fw-bold fz-xsmall fz-lg-smedium px-3"><i>@{{ interacao.label }}</i></span>
                                      <div style="width:60%" class="bg-medium-green text-yellow fw-bold p-0 me-0 text-center">
                                        <input class="h-100 p-2 fz-xsmall fst-italic text-center fz-lg-smedium w-100 text-white lh-sm border-0 bg-transparent" v-model="valorDevido" placeholder="R$ 0" type="text">
                                      </div>
                                    </div>
                                  </div>
                                  <!-- SELECT - TEMPO DE INADIMPLÊNCIA -->
                                  <div v-if="interacao.chatbot_inputs_id === 7" class="bg-yellow d-flex align-items-center justify-content-between mx-0 chat-balloon alternative mb-2 overflow-hidden onmouse">
                                    <div class="bg-yellow d-flex align-items-center justify-content-between mx-0 chat-balloon alternative mb-0 overflow-hidden">
                                      <span class="fw-bold fz-xsmall fz-lg-smedium px-3"><i>@{{ interacao.label }}</i></span>
                                      <div style="width:60%" class="bg-medium-green text-yellow fw-bold p-0 me-0 text-center">
                                        <select class="h-100 p-2 fz-xsmall fst-italic text-center fz-lg-smedium w-100 text-white lh-sm border-0 bg-transparent" v-model="tempoInadimplencia" @change="handleNegociacao(interacao.link_mensagens_id)">
                                          <option selected disabled hidden class="border-0 fz-xsmall fst-italic text-center fz-lg-smedium bg-medium-green">Selecione</option>
                                          <option :value="'12'" class="border-0 fz-xsmall fst-italic text-center fz-lg-smedium bg-medium-green">12 Meses</option>
                                          <option :value="'11'" class="border-0 fz-xsmall fst-italic text-center fz-lg-smedium bg-medium-green">11 Meses</option>
                                          <option :value="'10'" class="border-0 fz-xsmall fst-italic text-center fz-lg-smedium bg-medium-green">10 Meses</option>
                                          <option :value="'9'" class="border-0 fz-xsmall fst-italic text-center fz-lg-smedium bg-medium-green">9 Meses</option>
                                          <option :value="'8'" class="border-0 fz-xsmall fst-italic text-center fz-lg-smedium bg-medium-green">8 Meses</option>
                                          <option :value="'7'" class="border-0 fz-xsmall fst-italic text-center fz-lg-smedium bg-medium-green">7 Meses</option>
                                          <option :value="'6'" class="border-0 fz-xsmall fst-italic text-center fz-lg-smedium bg-medium-green">6 Meses</option>
                                        </select>
                                      </div>
                                    </div>
                                  </div>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
  
                        <div class="pi-digitando" v-show="pidigitando">
                          <b>PI está digitando uma nova mensagem<span class="typewriter">...</span></b>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
  
                <div class="pi-image-chat-wrapper col-12 col-lg-6 ps-lg-4">
                  <div class="img-assist-video d-flex justify-content-center h-100">
                    <img class="mt-auto" src="../../../../assets/images/assistent-mb.png" alt="">
                  </div>
                </div>
              </div>
            </section>
    
        </div>
      </BasePage>
    </div>
</template>
  
<script>
  import BasePage from '@/components/BasePage.vue'
  
  export default {
    name: "ChatView",
    components: {
      BasePage,
    },
    props: {
      apiUrl: String,
    },
    data() {
      return {
        dados: null,
        mensagens: [],
        ordem: 1,
        idProximaMensagem: 0,
        pidigitando: true,
        cpfCliente: null,
        emailCliente: null,
        valorDevido: null,
        tempoInadimplencia: null,
      };
    },
    mounted() {
      console.log('Carregando chatbot da PI aqui');
      console.log(this.apiUrl);
  
      this.checkCookie();
      this.dataMessages();
    },
    updated() {
      console.warn('######### CICLO DE VIDA DE ATUALIZAÇÃO #############');
      this.scrollToBottom();
    },
    methods: {
    setCookie() {
      console.log('Criando cookie...');
      const dataValidade = new Date();
      dataValidade.setFullYear(dataValidade.getFullYear() + 1);

      document.cookie = "cookie_atendimento_pi=true; expires=" + dataValidade.toUTCString();
    },
    checkCookie() {
      console.log('checando cookies...');
      // caso já tenha iniciado o atendimento antes 
      if (document.cookie.includes("cookie_atendimento_pi")) {
        this.ordem = 2;
        console.log('Tem cookie...')
      } else {
        console.log('Não tem cookie')
        this.ordem = 1;
        this.setCookie();
      } 
    },
    dataMessages() {
      this.pidigitando = true;
      let url = `${this.urlapi}/api/chatbot/pi/data`;

      console.log('Carregando as mensagens...');

      this.axios.get(url, 
              { 
                params: { 
                  ordem: this.ordem,
                  msg: this.idProximaMensagem
                }
            })
            .then((response) => {
                console.log('retorno da api > ', response)
                const mensagem = response.data.data.mensagem;
                const interacoes =  response.data.data.interacoes;

                this.pidigitando = false;
                this.mensagens.push({
                  "origem": 1, 
                  "msg": mensagem.mensagem, 
                  "tipo": mensagem.tipoMensagem, 
                  "interacoes": interacoes
                });

                this.ordem = response.data.data.proxOrdem;
                this.idProximaMensagem = 0;

                if (interacoes.length == 0 && mensagem.mensagemFinal == 0) {
                  this.dataMessages();                  
                }

                console.log('MENSAGENS ==========> ', this.mensagens);
                console.log('PRÓXIMA MENSAGEM ==> ORDEM ', this.ordem);
            })
            .catch((error) => {
              console.error('ERROR: ', error)
            });

    },
    setOpcao(index) {
      const letras = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

      if (index >= 0 && index < letras.length) {
        return letras[index];
      }

      return "";
    },
    linkProxMensagem(proxMensagem, mensagem) {
      console.log('chamando mensagem >> ' + proxMensagem)

      this.mensagens.push({
        "origem": 2, 
        "msg": mensagem, 
        "tipo": 1,
        "interacoes": null 
      });
      
      this.idProximaMensagem = proxMensagem;
      this.dataMessages();
    },

    handleCPF(proxMensagem) {
      console.log('chamando mensagem >> ' + this.ordem + ' e CPF: ' + this.cpfCliente)

      this.mensagens.push({
        "origem": 2, 
        "msg": this.cpfCliente, 
        "tipo": 1,
        "interacoes": null 
      });

      this.idProximaMensagem = proxMensagem
      this.dataMessages();

    },

    handleEMAIL() {
      console.log('chamando mensagem >> ' + this.ordem + ' e email: ' + this.emailCliente)

      this.mensagens.push({
        "origem": 2, 
        "msg": this.emailCliente, 
        "tipo": 1,
        "interacoes": null 
      });

      this.dataMessages();
    },

    handleNegociacao(proxMensagem) {

      this.mensagens.push({
        "origem": 2, 
        "msg": this.valorDevido + ' por ' + this.tempoInadimplencia + ' meses.', 
        "tipo": 1,
        "interacoes": null 
      });

      this.idProximaMensagem = proxMensagem
      this.dataMessages();
    },

    scroolToBottom() {
      const scrollContainer = this.$refs.scrollContent;
    //   const margem = (scrollContainer.scrollHeight * 0.5);
      scrollContainer.scrollTop += 200;
    },

  },
  };
  </script>
  
<style lang="css">
  .pi-digitando {
    background-color: rgb(207, 207, 207);
    animation: oscillate 3s linear infinite alternate;
    margin: 0 8px;
    display: flex;
    width: 85%;
    padding: 10px;
    color: #35a736;
    border-radius: 10px;
    justify-content: center;
    float: right;
  }
  
  /* Animação de oscilação */
  @keyframes oscillate {
    0% {
      background-color: rgba(207, 207, 207, 0.2); /* Baixa opacidade */
    }
    100% {
      background-color: rgba(207, 207, 207, 1); /* Alta opacidade */
    }
  }
  .onmouse {
    cursor: pointer;
  }
  </style>
  