<template>
  <div>
    <Header></Header>
    <slot />
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from './Footer.vue';
import Header from './Header.vue';


export default {
  name: "BasePage",
  components: {
    Header,
    Footer
  },
  methods: {
    
  },
}
</script>