<template>
  <div>

    <BasePage>
      <main class="home container-fluid d-flex flex-column justify-content-between flex-grow-1 p-0 position-relative">
        <div class="shadow-modal">
            <div class="modal-bem-vindo bg-medium-green d-flex flex-column px-4 pb-3 text-white">
                <img class="img-modal m-auto" src="../../../../assets/images/pi-modal.png" alt="Assistente virtual Pi">
                <h4 class="text-center">Bem-vindo(a) ao portal mais tecnológico da Sicredi Pioneira! Você será atendido pela nossa atendente virtual. </h4>
                <div class="w-100 d-flex flex-wrap">
                    <button id="play-Pi" class="btn btn-primary fst-italic mx-auto mt-4 mb-md-4 py-3 px-4 text-uppercase" @click="iniciarVideo">Clique aqui para falar com a PI!</button>
                    <button id="dont-play-Pi" class="btn btn-primary fst-italic mx-auto mt-4 mb-md-4 py-3 px-4 text-uppercase" @click="fecharModal">Não quero falar com a PI</button>
                </div>
            </div>
        </div>
        <section class="container container__banner-home p-0 flex-grow-1 d-flex align-items-center">
            <div class="row m-0 p-0">
                <div class="col-12 col-lg-4 m-0 p-0" style="z-index: 1">
                    <div class="d-flex justify-content-center pt-lg-3 pt-xxl-5 flex-column">
                        <h1 class="text-primary fw-light text-center fw-lg-regular text-lg-start fz-slarge px-3 pt-4 px-lg-0"><i>Bem-vindo ao <b class="fw-bold">Portal de Negociação</b> do <b class="fw-bold">Sicredi</b>. Veja como podemos <b class="fw-bold">te ajudar.</b></i></h1>
                        <p class="text-secondary text-center text-lg-start fw-light fw-lg-regular fz-small px-3 px-lg-0">Disponibilizamos várias formas para ajudar a resolver suas pendências financeiras, <b class="fw-bold">vem conversar com a gente</b>.</p>
                    </div>
                </div>
    
                <div class="bem-vindo-home col-12 col-lg-8 m-0 p-0">
                    <div class="row m-0 p-0">
                        <div class="col-6 col-lg-8 p-0 container-video" style="z-index: 0">
                            <div class="position-relative w-100 h-100">
                                <div class="volume-buttons">
                                    <button @click="diminuirVolume" class="volume__btn volume__btn--baixo">
                                        <img src="../../../../assets/images/volume-baixo.svg" alt="mutar Pi">
                                      </button>
                                      <button @click="aumentarVolume" class="volume__btn volume__btn--alto">
                                        <img src="../../../../assets/images/volume-alto.svg" alt="mutar Pi">
                                      </button>
                                </div>
                            </div>
                            <div id="pi-container">
                                <video id="piVideo" class="pi-home" ref="piHome" playsinline poster="../../../../assets/images/pi_poster.webp">
                                    <source src="/videos/Sicredi_PI_sequence_transparent_intro.webm" type="video/webm">
                                    <source src="https://sicredi.planteste.com.br/videos/Sicredi_PI_sequence_transparent_intro.mov" type="video/mp4">
                                    <source src="/videos/Sicredi_PI_sequence_transparent_intro.mp4" type="video/mp4">
                                </video>
                            </div> 
                            <!-- <video id="piVideo" class="pi-home" ref="piHome" playsinline>
                                <source src="/videos/Sicredi_PI_sequence_transparent_intro.webm" type="video/webm">
                                <source src="https://sicredi.planteste.com.br/videos/Sicredi_PI_sequence_transparent_intro.mov" type="video/mp4">
                                <source src="/videos/Sicredi_PI_sequence_transparent_intro.mp4" type="video/mp4">
                            </video> -->
                        </div>
    
                        <div class="col-6 col-lg-4 p-0 px-2 pt-sm-3" style="z-index: 1">
                            <div class="pt-lg-4 mt-1 mb-5 mb-lg-0 text-start">
                                <img class="w-40 w-lg-30 mt-2" src="../../../../assets/images/assistent-icon-mb.svg" alt="icone do assistente">
                                <h2 class="text-primary fz-smedium fz-sm-medium fz-lg-medium fz-xxl-xmedium mt-1"><i>Assistente <b class="fw-bold">Virtual</b></i></h2>
                                <p class="text-secondary fw-light fw-lg-regular fz-small"><a href="/chat" class="fw-bold text-decoration-none text-secondary">Clique aqui</a> para negociar com a <span class="fw-bold">Pi</span>, nossa atendente virtual.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>  
        </section>
    
        <section class="container-fluid bg-lg-dark-white position-relative container-avatars">
    
            <!-- PERSONAGENS -->
            <div class="container h-100 p-0">
                <div class="row position-relative m-0 p-0 avatars-position-top text-start">
                    <div class="col-12 col-lg-3 p-0 pb-5 pb-lg-0 position-relative hover-case">
                        <router-link :to="{ name: 'VideoView', params: { person: 'Pedro', video:'Sicredi-Pedro' } }" class="text-decoration-none">
                            <div class="row p-0 m-0">
                                <div class="col-6 p-0 container-img-avatar d-lg-flex">
                                    <div class="d-flex mh-100 justify-content-center justify-content-lg-start">
                                        <img class="w-60 w-lg-40 h-100" style="object-fit: contain; cursor: pointer;" src="../../../../assets/images/pedro.png" alt="">
                                    </div>
                                </div>
                                <div class="col-6 col-lg-12 p-0 d-flex justify-content-end flex-column pe-lg-5" style="cursor: pointer; pointer-events:auto">
                                    <h2 class="text-primary fz-smedium fz-xxl-medium mt-2"><i>Pedro</i></h2>
                                    <p class="text-secondary fw-light fz-xsmall fz-xxl-msmall">Conheça a história do <b class="fw-bold">Pedro</b>, e veja como o ajudamos a resolver seu problema com sua <span class="fw-bold text-secondary">loja</span>.</p>
                                </div>
                            </div>
                        </router-link>
                    </div>
    
                    <div class="col-12 col-lg-3 p-0 pb-5 pb-lg-0 position-relative hover-case">
                        <router-link :to="{ name: 'VideoView', params: { person: 'Marta', video: 'Sicredi-Marta' } }" class="text-decoration-none">
                            <div class="row p-0 m-0">
                              <div class="col-6 p-0 container-img-avatar d-lg-flex">
                                <div class="d-flex mh-100 justify-content-center justify-content-lg-start">
                                  <img class="w-60 w-lg-40 h-100" style="object-fit: contain; cursor: pointer;" src="../../../../assets/images/marta.png" alt="">
                                </div>
                              </div>
                              <div class="col-6 col-lg-12 p-0 d-flex justify-content-end flex-column pe-lg-5" style="cursor: pointer; pointer-events:auto">
                                <h2 class="text-primary fz-smedium fz-xxl-medium mt-2"><i>Marta</i></h2>
                                <p class="text-secondary fw-light fz-xsmall fz-xxl-msmall">Conheça a história da <b class="fw-bold">Marta</b>, e veja como a ajudamos a resolver seu problema com um <span class="fw-bold text-secondary">financiamento do carro</span>.</p>
                              </div>
                            </div>
                          </router-link>
                    </div>
    
                    <div class="col-12 col-lg-3 p-0 pb-5 pb-lg-0 position-relative hover-case">
                        <router-link :to="{ name: 'VideoView', params: { person: 'João', video: 'Sicredi-Joao' } }" class="text-decoration-none">
                            <div class="row p-0 m-0">
                                <div class="col-6 p-0 container-img-avatar d-lg-flex">
                                    <div class="d-flex mh-100 justify-content-center justify-content-lg-start">
                                        <img class="w-60 w-lg-50  h-100" style="object-fit: contain; cursor: pointer;" src="../../../../assets/images/joao.png" alt="">
                                    </div>
                                </div>
                                <div class="col-6 col-lg-12 p-0 d-flex justify-content-end flex-column pe-lg-5" style="cursor: pointer; pointer-events:auto">
                                    <h2 class="text-primary fz-smedium fz-xxl-medium mt-2"><i>João</i></h2>
                                    <p class="text-secondary fw-light fz-xsmall fz-xxl-msmall">Conheça a história do <b class="fw-bold">João</b>, e veja como o ajudamos a resolver seu problema com um <span class="fw-bold text-secondary">empréstimo</span>.</p>
                                </div>
                            </div>
                        </router-link>
                    </div>
    
                    <div class="col-12 col-lg-3 p-0 pb-0 position-relative hover-case">
                        <a class="text-decoration-none text-white" href="./game" style="cursor: default;">
                            <div class="row p-0 m-0">
                                <div class="col-6 p-0 container-img-avatar d-lg-flex">
                                    <div class="d-flex justify-content-center justify-content-lg-start">
                                        <img class="w-70 w-lg-50  h-100" style="object-fit: contain; cursor: pointer;" src="../../../../assets/images/game-home.png" alt="">
                                    </div>
                                </div>
                                <div class="col-6 col-lg-12 p-0 d-flex justify-content-end flex-column pe-lg-5" style="cursor: pointer; pointer-events:auto">
                                    <h2 class="text-primary fz-smedium fz-xxl-medium mt-2"><i>Jogo dos Sicréditos</i></h2>
                                    <p class="text-secondary fw-light fz-xsmall fz-xxl-msmall">Descubra como o <span class="fw-bold text-secondary">Jogo dos Sicréditos</span> pode te ajudar no seu planejamento financeiro.</p>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </section>
    </main>
    </BasePage>
    
  </div>
</template>

<script>
import BasePage from '@/components/BasePage.vue';
import { EventBus } from '@/store/event-bus';

// import webmVideo from '@/videos/Sicredi_PI_sequence_transparent_intro.webm';
// import movVideo from '@/videos/Sicredi_PI_sequence_transparent_intro.mov';

export default {
    name: "HomeView",
    components: {
        BasePage
    },
    data() {
        return {
            wasOpened: false,
        };
    },
    methods: {
        fecharModal() {
            const modal = document.querySelector('.shadow-modal');
            if (modal) {
                modal.classList.add('d-none');
            }
        },
        iniciarVideo() {
            const video = this.$refs.piHome;

            if (video.readyState >= 2) {
                video.play();
            } else {
                video.onloadedmetadata = function() {
                    video.play();
                };
            }

            this.fecharModal();
        },
        diminuirVolume() {
            const video = this.$refs.piHome;
            if (video && video.volume > 0) {
                if (video.volume <= 0.09) {
                video.volume = 0;
                return;
                }
                video.volume -= 0.1;
                this.addOpacidadeAoChegarNoLimite(video);
            }
        },
        aumentarVolume() {
            const video = this.$refs.piHome;
            if (video && video.volume < 1.0) {
                if (video.volume >= 0.91) {
                video.volume = 1;
                return;
                }
                video.volume += 0.1;
                this.addOpacidadeAoChegarNoLimite(video);
            }
        },
        addOpacidadeAoChegarNoLimite(video) {
            const botoesDiminuirVolume = document.querySelectorAll('[data-video-volume="diminuir"]');
            const botoesAumentarVolume = document.querySelectorAll('[data-video-volume="aumentar"]');

            if (video.volume < 1) {
                botoesAumentarVolume.forEach((botaoAumentar) => {
                botaoAumentar.style.opacity = '1';
                });
            } else {
                botoesAumentarVolume.forEach((botaoAumentar) => {
                botaoAumentar.style.opacity = '0.5';
                });
            }

            if (video.volume > 0) {
                botoesDiminuirVolume.forEach((botaoDiminuir) => {
                botaoDiminuir.style.opacity = '1';
                });
            } else {
                botoesDiminuirVolume.forEach((botaoDiminuir) => {
                botaoDiminuir.style.opacity = '0.5';
                });
            }
        },
    },
    mounted() {
        EventBus.$on("diminuir-volume", this.diminuirVolume);
        EventBus.$on("aumentar-volume", this.aumentarVolume);
    },
}
</script>