import Home from "@/views/public/pages/home/Home.vue";
import Contato from "@/views/public/pages/contato/Contato.vue";
import Chat from "@/views/public/pages/chat/Chat.vue"
import Game from "@/views/public/pages/game/Game.vue";
import HowToPlay from "@/views/public/pages/game/HowToPlay.vue";
import PlayGame from "@/views/public/pages/game/PlayGame.vue";
import EndGame from "@/views/public/pages/game/EndGame.vue";
import Video from "@/views/public/pages/video/Video.vue"

export default [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/contato",
    name: "Contato",
    component: Contato
  },
  {
    path: "/chat",
    name: "Chat",
    component: Chat
  },
  {
    path: "/game",
    name: "Game",
    component: Game
  },
  {
    path: "/game/como-jogar",
    name: "HowToPlay",
    component: HowToPlay
  },
  {
    path: "/game/play",
    name: "PlayGame",
    component: PlayGame
  },
  {
    path: "/game/final-game",
    name: "EndGame",
    component: EndGame
  },
  {
    path: '/video/:person/:video',
    name: 'VideoView',
    component: Video
  }
];
