<template>
    <div>
      <BasePage>
        <div class="d-flex flex-column align-items-center py-5">
            <img class="w-100 img-index" src="../../../../assets/images/logo-game.png" alt="Logo Sicréditos">
            <!-- <div class="btn btn-primary ms-1 ms-md-2 my-3 my-sm-4 px-3 px-md-4 fz-md-medium fz-xxl-large fw-semi-bold fst-italic fz-small" onclick="window.location='{{ route('play') }}'">INICIAR</div> -->
            <router-link  to="/game/play">
              <div class="btn btn-primary ms-1 ms-md-2 my-3 my-sm-4 px-3 px-md-4 fz-md-medium fz-xxl-large fw-semi-bold fst-italic fz-small">INICIAR</div>
            </router-link>
            <!-- <a href="{{ route('how-to-play') }}" class="text-yellow fw-bold text-decoration-none fz-small lh-1 fz-sm-medium fst-italic">Como jogar</a> -->
            <a href="./game/como-jogar" class="text-yellow fw-bold text-decoration-none fz-small lh-1 fz-sm-medium fst-italic">Como jogar</a>
        </div>
        
      </BasePage> 
    </div>
  </template>
  
  <script>
  import BasePage from '@/components/BasePage.vue';
  
  export default {
    name: "GameView",
    components: {
      BasePage
    }
  }
  </script>