import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

import "@fortawesome/fontawesome-free/css/all.css";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: "#64c832",
        secondary: "#ffffff",
        accent: "#146e37",
        error: "#e60050",
        warning: "#ffcd00",
        input: "#64c832",
        element: "#ffffff",
        subheader: "#146e37",
      },
      dark: {
        primary: "#88eb57",
        secondary: "#202020",
        accent: "#5a645a",
        error: "#e60050",
        warning: "#ffcd00",
        input: "#64c832",
        element: "#323c32",
        subheader: "#88eb57",
      },
    },
  },
  icons: {
    iconfont: "fa",
  },
});
