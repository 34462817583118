<template>
    <div>
        <BasePage>
            <div class="position-relative d-flex flex-column align-items-center px-md-5 mx-1 mx-md-5 py-3 py-xxl-5 mb-3 mb-xxl-5">
                <img style="height: 150px; z-index: 1" class="position-absolute" src="../../../../assets/images/logo-game.png"
                    alt="Logo Sicréditos">
                <div class="d-flex w-100 mx-5 flex-column align-items-center position-relative semitransparent-container--extra-dark-green"
                    style="margin-top: 100px; max-width: 1050px">
                    <div class="px-md-5 py-4 switch-feedback">
                        <div class="row mt-xxl-3">
                            <div class="col-4 m-0 d-flex flex-row justify-content-end align-items-center">
                                <img class="w-75 w-xxl-100" src="../../../../assets/images/left-coins.png" alt="Moedas">
                            </div>
                            <div class="col-4 m-0 d-flex flex-column justify-content-center align-items-center">
                                <h2 class="mt-5 mb-lg-1 mb-4 my-xxl-4 text-center fz-small fw-semi-bold fst-italic fz-md-large">Resultado:</h2>
                                <p class="text-white fz-medium fz-xl-large fz-xxl-xlarge fw-semi-bold text-center lh-1 fst-italic p-0 m-0">Você
                                    juntou</p>
                                <!-- VALOR TOTAL DA PONTUAÇÃO -->
                                <p class="text-yellow fz-xlarge fz-md-xxlarge fz-xxl-4xlarge fw-bold text-center lh-1 fst-italic p-0 m-0"> 
                                    {{ valorTotal }}
                                </p>
                                <p class="text-white fz-medium fz-xl-large fz-xxl-xlarge fw-semi-bold text-center lh-1 fst-italic p-0 m-0">
                                    Sicréditos!</p>
                            </div>
        
                            <div class="col-4 m-0 d-flex flex-row justify-content-start align-items-center">
                                <img class="w-75 w-xxl-100" src="../../../../assets/images/right-coins.png" alt="Moedas">
                            </div>
                        </div>
                        <div class="px-md-5 d-flex justify-content-center text-white">
                            <p class="text-center mt-xxl-2 px-3 pt-2 px-lg-5 fz-xsmall mb-0 fz-md-small" style="max-width: 600px;">
                                <!-- @if(request('value')>=100)
                                <strong>Você é uma pessoa super organizada financeiramente! Parabéns!</strong> Por que você não
                                aprimora ainda mais
                                seus conhecimentos com os nossos conteúdos exclusivos da Sicredi Pioneira?
                                @elseif(request('value')>=80)
                                Você é uma pessoa com ótimo conhecimento financeiro, mas o aprendizado nunca é demais né?!
                                @elseif(request('value')>=50)
                                Você pode deixar ainda melhor a sua vida financeira, que tal aprender junto conosco?
                                @else
                                Fique tranquilo, a gente entende, mas que tal melhorar o seu aprendizado financeiro? Nós podemos te ajudar!
                                @endif -->
                                <strong>{{ getScoreMessage }}</strong>
                            </p>
                        </div>
                        <h2 class="feedback-trigger mt-2 mt-lg-0 pb-2 pt-xxl-2 mb-lg-3 mb-xxl-4 text-center fz-small fw-semi-bold fz-md-xmedium text-secondary">Clique aqui e saiba o que você acertou.</h2>
                        <div class="d-flex flex-row justify-content-center m-0">
                           <div class="position-absolute d-flex" style="bottom: -40px">
                                
                                <router-link to="/game/play" class="btn btn-primary me-1 me-md-2 my-3 px-2 px-md-4 fz-md-medium fz-lg-large fw-semi-bold fst-italic fz-small">
                                    Jogar novamente
                                </router-link>
                                   
                                <router-link to="/" class="btn btn-primary ms-1 ms-md-2 my-3 px-2 px-md-4 fz-md-medium fz-lg-large fw-semi-bold fst-italic fz-small">
                                    Sair do jogo
                                </router-link>
                            </div>
                        </div>
        
                    </div>
                    <div class="px-2 px-md-5 py-4 w-100 switch-feedback d-none">
                        <div class="d-flex w-100 justify-content-between">
                            <h1 class="mt-5 mt-lg-0 fz-medium fz-lg-large fw-semi-bold fst-italic">Feedback das perguntas:</h1>
                            <img class="me-2 me-lg-0 mt-5 mt-lg-2 feedback-size-close-img feedback-trigger" src="../../../../assets/images/close.png" alt="">
                        </div>
                        <div class="mt-4 mb-2">
                            <div class="semitransparent-container--medium-green px-3 py-4 my-3 w-100 fz-smedium ">
                                <div class="py-2 d-flex justify-content-between">
                                    <p id="feedback-quest-0" class="fw-bold m-0 fst-italic">Você precisou comprar uma geladeira e a loja te oferece três formas de pagamento. Qual a melhor?</p>
                                    <div style="width: 40px;" class="mx-3 trigger-quest-response">
                                        <img class="feedback-arrow-img" src="../../../../assets/images/arrow-feedback.png" alt="">
                                    </div>
                                </div>
                                <div id="feedback-response-0" class="quest-response h-0 fw-regular fz-msmall m-0 lh-sm fst-italic d-flex flex-column justify-content-center">
                                    <p class="fw-semi-bold fz-msmall m-0 lh-sm fst-italic">
                                        Você sabe quais as vantagens de dividir com seu parceiro a sua vida financeira?
                                    </p>
                                    <p class="fw-regular fz-msmall m-0 lh-sm fst-italic">
                                        “Estudos demonstram que falar de forma sincera e verdadeira sobre finanças, além de aproximar o casal, ajudam a manter a saúde financeira estável e na conquista de metas.”
                                    </p>
                                </div>
                            </div>
                            <div class="semitransparent-container--medium-green px-3 py-4 my-3 w-100 fz-smedium ">
                                <div class="py-2 d-flex justify-content-between">
                                    <p id="feedback-quest-1" class="fw-bold m-0 fst-italic">Você precisou comprar uma geladeira e a loja te oferece três formas de pagamento. Qual a melhor?</p>
                                    <div style="width: 40px;" class="mx-3 trigger-quest-response">
                                        <img class="feedback-arrow-img" src="../../../../assets/images/arrow-feedback.png" alt="">
                                    </div>
                                </div>
                                <div id="feedback-response-1" class="quest-response h-0 fw-regular fz-msmall m-0 lh-sm fst-italic d-flex flex-column justify-content-center">
                                    <p class="fw-semi-bold fz-msmall m-0 lh-sm fst-italic">
                                        Você sabe quais as vantagens de dividir com seu parceiro a sua vida financeira?
                                    </p>
                                    <p class="fw-regular fz-msmall m-0 lh-sm fst-italic">
                                        “Estudos demonstram que falar de forma sincera e verdadeira sobre finanças, além de aproximar o casal, ajudam a manter a saúde financeira estável e na conquista de metas.”
                                    </p>
                                </div>
                            </div>
                            <div class="semitransparent-container--medium-green px-3 py-4 my-3 w-100 fz-smedium ">
                                <div class="py-2 d-flex justify-content-between">
                                    <p id="feedback-quest-2" class="fw-bold m-0 fst-italic">Você precisou comprar uma geladeira e a loja te oferece três formas de pagamento. Qual a melhor?</p>
                                    <div style="width: 40px;" class="mx-3 trigger-quest-response">
                                        <img class="feedback-arrow-img" src="../../../../assets/images/arrow-feedback.png" alt="">
                                    </div>
                                </div>
                                <div id="feedback-response-2" class="quest-response h-0 fw-regular fz-msmall m-0 lh-sm fst-italic d-flex flex-column justify-content-center">
                                    <p class="fw-semi-bold fz-msmall m-0 lh-sm fst-italic">
                                        Você sabe quais as vantagens de dividir com seu parceiro a sua vida financeira?
                                    </p>
                                    <p class="fw-regular fz-msmall m-0 lh-sm fst-italic">
                                        “Estudos demonstram que falar de forma sincera e verdadeira sobre finanças, além de aproximar o casal, ajudam a manter a saúde financeira estável e na conquista de metas.”
                                    </p>
                                </div>
                            </div>
                            <div class="semitransparent-container--medium-green px-3 py-4 my-3 w-100 fz-smedium ">
                                <div class="py-2 d-flex justify-content-between">
                                    <p id="feedback-quest-3" class="fw-bold m-0 fst-italic">Você precisou comprar uma geladeira e a loja te oferece três formas de pagamento. Qual a melhor?</p>
                                    <div style="width: 40px;" class="mx-3 trigger-quest-response">
                                        <img class="feedback-arrow-img" src="../../../../assets/images/arrow-feedback.png" alt="">
                                    </div>
                                </div>
                                <div id="feedback-response-3" class="quest-response h-0 fw-regular fz-msmall m-0 lh-sm fst-italic d-flex flex-column justify-content-center">
                                    <p class="fw-semi-bold fz-msmall m-0 lh-sm fst-italic">
                                        Você sabe quais as vantagens de dividir com seu parceiro a sua vida financeira?
                                    </p>
                                    <p class="fw-regular fz-msmall m-0 lh-sm fst-italic">
                                        Faça um exercício sincero e escreva quais foram as melhores e as piores decisões financeiras. Isso vai ampliar teu autoconhecimento e te ajudar a planejar as compras do seu bebe!
                                    </p>
                                    <p class="fw-regular fz-msmall m-0 lh-sm fst-italic">
                                        Para conhecer mais, acesse o site de educação financeira - https://sicredipioneira.com.br/educacaofinanceira/ e,
                                    </p>
                                    <p class="fw-regular fz-msmall m-0 lh-sm fst-italic">
                                        Lembre-se: autoconhecimento é a chave para superação!
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </BasePage> 
    </div>
  </template>
  
  <script>
  import BasePage from '@/components/BasePage.vue';
  
  export default {
    name: "EndGameView",
    components: {
      BasePage
    },
    mounted() {

    },
    computed: {
        valorTotal() {
            return this.$route.params.valorTotal
        },
        getScoreMessage() {
            const score = parseInt(this.$route.params.valorTotal) || 0;
            if (score >= 100) {
                return "Você é uma pessoa super organizada financeiramente! Parabéns!";
            } else if (score >= 80) {
                return "Você é uma pessoa com ótimo conhecimento financeiro, mas o aprendizado nunca é demais né?!";
            } else if (score >= 50) {
                return "Você pode deixar ainda melhor a sua vida financeira, que tal aprender junto conosco?";
            } else {
                return "Fique tranquilo, a gente entende, mas que tal melhorar o seu aprendizado financeiro? Nós podemos te ajudar!";
            }
        },
    },
  }
  </script>