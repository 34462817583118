<template>
  <div class="bg-white w-100 position-relative" style="z-index: 999;">
    <!-- DESKTOP -->
    <div class="header container d-none d-md-flex d-flex flex-row justify-content-between w-100 align-items-center h-40">
        <a class="none-hover" href="/">
            <img src="../assets/images/logo.svg" alt="Sicredi Logo">
        </a>
        <nav>
            <ul class="d-flex flex-row justify-content-center gap-4 text-dark-green list-unstyled m-0">
                <li>
                  <router-link to="/game" class="nav-link text-decoration-none text-dark-green fw-semi-bold">
                    Jogo dos Sicréditos
                  </router-link>
                </li>
          
                <li>
                  <router-link to="/chat" class="nav-link text-decoration-none text-dark-green fw-semi-bold">
                    Assistente Virtual
                  </router-link>
                </li>
                <li>
                  <router-link to="/contato" class="nav-link text-decoration-none text-dark-green fw-semi-bold">
                    Contato
                  </router-link>
                </li>
            </ul>
        </nav>

        <!-- VOLUME BUTTONS -->
        <div class="volume-buttons">
            <button @click="diminuirVolume" class="volume__btn volume__btn--baixo">
                <img  src="../assets/images/volume-baixo.svg" alt="mutar Pi">
            </button>
            <button @click="aumentarVolume" class="volume__btn volume__btn--alto">
                <img  src="../assets/images/volume-alto.svg" alt="Aumentar volume Pi">
            </button>
        </div>
    </div>
    
      <!-- MOBILE -->
    <div class="container px-3 px-md-0 px-sm-0 pt-2 pt-md-0">
        <header class="w-100 position-relative d-md-none px-0 mx-0 py-1 d-flex justify-content-between align-items-center">
          <div class="d-flex justify-content-center">
            <a class="none-hover logo" href="./">
              <img src="../assets/images/logo.svg" alt="Sicredi Logo">
            </a>
          </div>
          <div class="end-0 trigger-menu position-absolute" id="menu-container" style="z-index: 12">
            <button @click="toggleMobileMenu" id="menu-active">
              <div id="hamburger" v-if="!mobileMenuVisible"></div>
            </button>
          </div>
        </header>
    </div>
  
  
      <!-- MENU MOBILE -->
      <nav v-if="mobileMenuVisible" class="top-0 end-0 position-fixed d-flex flex-column align-items-center h-100 bg-bg menu-mobile d-xl-none">
        <div id="close-icon" class="text-white position-absolute" style="right: 50px;" @click="closeMobileMenu">
          <img src="../assets/images/cancel-icon.png" alt="Ícone Cancelar">
        </div>
        
        <div class="mx-3 ps-5 my-auto menu-width list-unstyled">
          <li class="mb-3">
            <a href="./game" class="text-decoration-none text-white fw-semi-bold">
              Jogo dos Sicréditos
            </a>
          </li>
          <li class="mb-3">
            <a href="./chat" class="text-decoration-none text-white fw-semi-bold">
              Assistente Virtual
            </a>
          </li>
          <li class="mb-3">
            <a href="./contact" class="text-decoration-none text-white fw-semi-bold">
              Contato
            </a>
          </li>
        </div>
    </nav>
</div>
</template>
<script>
import { EventBus } from '@/store/event-bus';

export default {
  name: "HeaderComponent",
  data() {
    return {
      mobileMenuVisible: false,
    };
  },
  components: {},
  methods: {
    diminuirVolume() {
      EventBus.$emit("diminuir-volume");
    },
    aumentarVolume() {
      EventBus.$emit("aumentar-volume");
    },
    toggleMobileMenu() {
      this.mobileMenuVisible = !this.mobileMenuVisible;
    },
    closeMobileMenu() {
      this.mobileMenuVisible = false;
    },
  },
}
</script>
