<template>
    <section class="bg-lg-footer footer">
   
        <div class="container container_footer px-0 flex-column d-flex justify-content-center align-items-center py-2 py-xxl-3">
           
            <div class="container_footer-links d-flex flex-column">
                <div class="footer-logo">
                    <a class="none-hover" href="" >
                        <img class="" src="../assets/images/logo.svg" alt="Sicredi Logo">
                    </a>
                </div>
                
                <div class="footer-links" >
                    <a class="text-decoration-none text-primary" href="">Para Você</a>
                    <a class="text-decoration-none text-primary" href="">Para Empresa</a>
                    <a class="text-decoration-none text-primary" href="">Para Agronegócio</a>
                    <a class="text-decoration-none text-primary" href="">Para Comunidade</a>
                    <a class="text-decoration-none text-primary" href="">Quem Somos</a>
                    <a class="text-decoration-none text-primary" href="">Blog</a>
                    <a class="text-decoration-none text-primary" href="">Fale Conosco</a>
                </div>
            </div>
    
            <div class="linha"></div>
    
            <div class="container_footer-informacao">
                <div class="footer-informacao-items">
                    <div class="d-flex flex-column gap-lg-0 justify-content-start justify-content-lg-between fz-small">
                        <div><h2>Canais de atendimento</h2></div>
                        <div><h3>Em caso de dúvidas, sugestões ou reclamações.</h3></div>
                        <div class="text-primary fw-semi-bold">
                            (51) 3358-4470
                        </div>
                        <div class="text-primary fw-semi-bold">  
                            (54) 3281-8100
                        </div>
                    </div>
                </div>
    
                <div class="footer-informacao-items">
                    <div class="d-flex flex-column gap-lg-0 justify-content-start justify-content-lg-between fz-small">
                        <div><h2>SAC</h2></div>
                        <div><h3>Informações, elogios e reclamações.</h3></div>
                        <div class="text-primary fw-semi-bold">  
                            0800 724 7220
                        </div>
                    </div>
                </div>
    
                <div class="footer-informacao-items">
                    <div class="d-flex flex-column gap-lg-0 justify-content-start justify-content-lg-between fz-small">
                        <div><h2>Ouvidoria</h2></div>
                        <div><h3>Reclamações e denúncias.</h3></div>
                        <div class="text-primary fw-semi-bold">  
                            0800 646 2519
                        </div>
                    </div>
                </div>
    
                <div class="footer-informacao-items">
                    <div class="d-flex flex-column gap-lg-0 justify-content-start justify-content-lg-between fz-small">
                        <div><h2>Atendimento</h2></div>
                        <div><h3>Auditivos ou de fala.</h3></div>
                        <div class="text-primary fw-semi-bold">  
                            0800 724 0525
                        </div>
                    </div>
                </div>
    
            </div>
    
            <div class="linha"></div>
    
            <div class="container_footer-contatos">
                <div class="footer-contatos-items">
                    <div class="contatos-item">
                        <div class="contatos-item-icons">
                            <img src="../assets/images/icons-facebook.svg" alt="Facebook">
                            <img src="../assets/images/icons-instagram.svg" alt="Instagram">
                            <img src="../assets/images/icons-youtube.svg" alt="Youtube">
                            </div>
                        <h2>sicredipioneirars</h2>
                    </div>
                    <div class="contatos-item">
                        <div class="contatos-item-icons">
                            <img src="../assets/images/icons-email.svg" alt="E-mail">
                        </div>
                        <h2>cooperativa@sicredipioneira.com.br</h2>
                    </div>
                    <div class="contatos-item">
                        <div class="contatos-item-icons">
                            <img src="../assets/images/icons-localizacao.svg" alt="Icone Localização">
                        </div>
                        <h2>Rua Sete de Setembro, 374 - Nova Petrópolis</h2>
                    </div>
                </div>
            </div>
    
            <div class="linha ultima-linha"></div>
    
            <div class="container_footer-lgpd">
                <div class="footer-lgpd-item">
                    <h2>Copyright - Sicredi Pioneira 2023</h2>
                </div>
                <div class="footer-lgpd-item">
                    <div class="lgpd-link">
                        <a>Trabalhe Conosco</a>
                        <img src="../assets/images/icon-direcionamento.svg" alt="Icone direcionamento">
                    </div>
                    <div class="lgpd-link"><a>Políticas de Privacidade</a></div>
                    <div class="lgpd-link"><a>Políticas de Cookies</a></div>
                </div>
            </div>
           
        </div>
    
    </section>
    
</template>


<script>
export default {
    name: "FooterComponent",
    components: {}
}
</script>