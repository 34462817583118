<template>
    <div>
        <BasePage>

        <div class="container p-0 d-flex flex-column flex-md-row mt-4 mb-2">
          <section class="container px-0 col-md-5">
            <div class="d-flex justify-content-center flex-column px-3 col-md-10">
              <h1 class="text-primary text-center text-md-start fz-xmedium fz-md-large fz-xxl-xlarge">
                <i>Quer enviar uma <b class="fw-bold">mensagem</b> pra gente?</i>
              </h1>
              <p class="text-secondary text-center text-md-start fz-small">Então basta preencher os campos e enviar sua mensagem. Responderemos o mais rápido possível. Você também pode utilizar os seguintes canais:</p>
            </div>
            <div class="row col-12 mx-0 py-3">
              <div class="d-flex flex-column justify-content-start align-items-center align-items-md-start col-6 col-md-5 col-xxl-4">
                <img class="contact-image-icon" src="../../../../assets/images/whatsapp-icon-escuro.svg" alt="Whatsapp" />
                <h2 class="text-primary text-center text-md-start fz-xmedium py-2 pb-md-0"><i>Whatsapp</i></h2>
                <p class="text-center text-md-start fz-small">
                  <span class="fz-md-xsmall text-secondary">
                    <a href="" class="text-secondary" rel="nofollow"
                      ><strong class="fw-bold">Clique aqui</strong></a
                    >
                    para falar conosco via <strong class="fw-bold">Whatsapp</strong> e veja como podemos te ajudar.
                  </span>
                </p>
              </div>
              <div class="d-flex flex-column justify-content-start align-items-center align-items-md-start col-6 col-md-5 col-xxl-4">
                <img class="contact-image-icon" src="../../../../assets/images/call-icon-escuro.svg" alt="Ligação" />
                <h2 class="text-primary text-center text-md-start fz-xmedium py-2 pb-md-0"><i>Ligação</i></h2>
                <p class="text-center text-md-start fz-small text-secondary">
                  <span class="fz-md-xsmall">Se preferir falar conosco por telefone, ligue para:<br> <strong class="text-secondary">0800 7240525</strong></span>
                </p>
              </div>
            </div>
          </section>
          <section class="px-3 col-md-7" id="contactApp">
            <contact :cities-endpoint="citiesEndpoint">
              <form action="" method="post" @submit="onSubmit" ref="form">
                <div class="d-flex flex-column flex-lg-row gap-4 justify-content-between">
                  <div class="w-100 pe-lg-1">
                    <div class="d-flex flex-column mb-1">
                      <label class="text-secondary fw-bold"><i>Nome*</i></label>
                      <input class="transparent-input" type="text" placeholder="Digite seu nome" name="name" v-model.trim="data.name" @input="checkNameLength" />
                    </div>
                    <div class="d-flex flex-column mb-1">
                      <label class="text-secondary fw-bold"><i>E-mail*</i></label>
                      <input class="transparent-input" type="email" placeholder="Digite seu e-mail" name="email" v-model.trim="data.email" />
                    </div>
                    <div class="d-flex flex-column mb-1">
                      <label class="text-secondary fw-bold"><i>Celular*</i></label>
                      <input class="transparent-input" type="tel" placeholder="(51) 99999.9999" name="phone" v-model.trim="data.phone" @input="cleanPhone" v-mask="['(##) ####.####', '(##) #####.####']" />
                    </div>
                    <div class="d-flex flex-column mb-1" style="cursor:pointer;">
                      <label class="text-secondary fw-bold"><i>Estado*</i></label>
                      <select class="transparent-select" name="state" v-model="data.state" @change="onChangeState" style="cursor:pointer;">
                        <option :value="null" disabled hidden>Selecionar</option>
                        @foreach ($states as $state)
                        <!-- <option class="bg-dark-green" value="{{ $state->short_title }}">{{ $state->title }}</option> -->
                        <option class="bg-dark-green" value="">Titulo</option>
                        @endforeach
                      </select>
                    </div>
                    <div class="d-flex flex-column mb-1" style="cursor:pointer;">
                      <label class="text-secondary fw-bold"><i>Cidade*</i></label>
                      <select class="transparent-select" name="city" v-model="data.city" @change="onChangeCity" style="cursor:pointer;">
                        <option class="bg-dark-green" :value="null" disabled hidden>Selecionar</option>
                        <!-- <option class="bg-dark-green" v-for="city in cities" :value="city.title">@{{ city.title }}</option> -->
                        <option class="bg-dark-green">Titulo</option>
                      </select>
                    </div>
                  </div>
      
                  <div class="d-flex flex-column mb-1 w-100 contact-textarea-wrapper ps-lg-1">
                    <label class="text-secondary fw-bold"><i>Mensagem*</i></label>
                    <textarea class="transparent-textarea h-100" placeholder="Escreva sua mensagem..." name="message" v-model.trim="data.message"></textarea>
                  </div>
                </div>
      
                <div class="d-lg-flex flex-row align-items-center gap-2">
                  <div class="py-1 checkbox-styles">
                    <label for="confirmation" class="fz-xsmall d-flex flex-row gap-2" style="cursor:pointer;">
                      <input id="confirmation" type="checkbox" class="transparent-checkbox" name="terms" v-model="data.terms" />
                      <span></span>
                      <div class="ps-2 text-secondary">
                        Autorizo que os meus dados pessoais (nome, e-mail, telefone e cidade onde resido) sejam utilizados para finalidade de retorno ao contato solicitado. Após término do tratamento dos dados, os mesmos serão descartados, em conformidade com Lei 13.709/18, Lei Geral de Proteção de Dados.<br
                        /><span class="text-secondary">* Campos de preenchimento obrigatório.</span>
                      </div>
                    </label>
                  </div>
      
                  <div class="w-100 d-flex flex-row justify-content-center">
                    <button type="submit" class="btn btn-contact bg-primary text-white ms-1 ms-md-2 my-3 my-sm-4 px-3 px-md-4 fz-md-medium fz-xxl-large fw-semi-bold fz-small">
                      ENVIAR
                    </button>
                  </div>
                </div>
                <vue-element-loading :active="loading" background-color="transparent" color="#323C32" />
              </form>
            </contact>
          </section>
        </div>

    </BasePage>
</div>
  </template>
  
  <script>
  import axios from 'axios';
  import VueElementLoading from 'vue-element-loading';
  import BasePage from '@/components/BasePage.vue';
  
  export default {
    name: "ContatoView",
    components: {
      VueElementLoading,
      BasePage,
    },
    props: {
      citiesEndpoint: String,
    },
  
    data: function () {
      return {
        data: {},
        defaults: {
          name: "",
          email: "",
          phone: "",
          state: null,
          city: null,
          message: "",
          terms: false,
        },
        classes: {},
        cities: [],
        states: {},
        loading: false,
      };
    },
  
    mounted: function () {
      this.resetForm();
      this.onChangeState();
    },
  
    methods: {
      resetForm() {
        this.data = Object.assign({}, this.defaults);
      },
  
    //   onChangeState: function (event) {
    //     if (this.data.state) {
    //       this.loading = true;
    //       axios
    //         .get(this.citiesEndpoint, {
    //           params: {
    //             short: this.data.state,
    //           },
    //         })
    //         .then((response) => {
    //           this.cities = response.data;
    //           if (this.states[this.data.state]) {
    //             this.data.city = this.states[this.data.state];
    //           } else {
    //             this.data.city = null;
    //           }
    //         })
    //         .finally(() => {
    //           this.loading = false;
    //         });
    //     }
    //   },
  
    //   onChangeCity: function (event) {
    //     this.states[this.data.state] = this.data.city;
    //   },
  
    //   checkNameLength: function (event) {
    //     if (this.data.name.length >= 10) {
    //       this.$toasted.warning("Nome não pode exceder 10 caracteres", { duration: 3000 });
    //       this.data.name = this.data.name.slice(0, 10);
    //     }
    //   },
  
      cleanPhone: function () {
        this.data.phone = this.data.phone.replace(/[^0-9.]/g, "");
      },
  
      onSubmit: function (event) {
        event.preventDefault();
        if (this.loading) return;
        this.loading = true;
        this.classes = {};
        var formData = new FormData(this.$refs.form),
          url = this.$refs.form.getAttribute("action");
        axios
          .post(url, formData)
          .then(this.onComplete.bind(this))
          .catch(this.onError.bind(this))
          .finally(() => {
            this.loading = false;
          });
      },
  
      onComplete: function (response) {
        this.$toasted.success(response.data.message, { duration: 3000 });
        this.resetForm();
      },
  
    //   onError: function (error) {
    //     var vm = this;
    //     _.each(error.response.data.errors, function (v, k) {
    //       vm.classes[k] = { "is-invalid": true };
    //       _.each(v, function (s) {
    //         vm.$toasted.error(s, { duration: 3000 });
    //       });
    //     });
    //   },
    },
  };
  </script>
  