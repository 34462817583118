<template>
    <div>
      <BasePage>
        <div class="position-relative d-flex flex-column align-items-center px-lg-5 mx-lg-5 py-3 py-xxl-5 mb-5">
            <img style="height: 150px; z-index: 1" class="position-absolute" src="../../../../assets/images/logo-game.png" alt="Logo Sicréditos">
            <div class="d-flex w-100 flex-column align-items-center position-relative semitransparent-container--medium-green text-white"
                style="margin-top: 100px">
                <div class="px-3 px-lg-5 py-4 mt-5 mt-lg-0 ">
                    <div class="d-flex flex-row justify-content-between align-items-center">
                        <h2 class="text-yellow fst-italic fw-bold m-0">Como jogar</h2>
                        <img @click="redirectToGame"
                            style="height: 25px; width: 25px; cursor: pointer" src="../../../../assets/images/close.png"
                            alt="Fechar">
                    </div>
                    <div class="d-flex flex-column flex-lg-row gap-5 mt-3 text-start">
                        <div>
                            <p>
                                Você deve assinalar no quiz as respostas que correspondem com as atitudes na sua vida
                                financeira.
                                O jogo é composto com quatro perguntas, e para lhe ajudar vamos te dar <span
                                    class="text-yellow fw-semi-bold">75 Sicréditos</span> para começar
                                esse
                                desafio!
                            </p>
                            <p class="py-1">
                                Quanto mais rápido você responder as questões com diferentes limites de tempo, menos Sicréditos
                                você
                                perde, já que perderá <span class="fw-semi-bold">1 Sicrédito</span> por segundo.
                            </p>
                            <p class="fw-semi-bold">
                                O tempo de resposta para cada pergunta é o seguinte:
                            </p>
                            <div>
                                <ul class="list-unstyled">
                                    <li class="fst-italic fz-medium">
                                        <strong>1ª pergunta:
                                            <span class="text-yellow">até 30 segundos</span>
                                        </strong>
                                    </li>
                                    <li class="fst-italic fz-medium">
                                        <strong>2ª pergunta:
                                            <span class="text-yellow">até 20 segundos</span>
                                        </strong>
                                    </li>
                                    <li class="fst-italic fz-medium">
                                        <strong>3ª pergunta:
                                            <span class="text-yellow">até 15 segundos</span>
                                        </strong>
                                    </li>
                                    <li class="fst-italic fz-medium">
                                        <strong>4ª pergunta:
                                            <span class="text-yellow">até 10 segundos</span>
                                        </strong>
                                    </li>
                                </ul>
                            </div>
                        </div>
        
                        <div>
                            <p>
                                O tempo começará a contar após o período de leitura.
                            </p>
                            <p>
                                Cada pergunta possui uma bonificação de Sicréditos de acordo com a resposta escolhida.
                                Dependendo da
                                resposta escolhida você pode receber 10, 15 ou até 20 Sicréditos.
                            </p>
                            <h2 class="fst-italic fw-bold my-5">Quantos Sicréditos você consegue conquistar? </h2>
    
                            <div class="d-flex flex-row justify-content-center m-0">
                                <router-link to="/game" style="bottom: -30px" 
                                    class="btn position-absolute btn-primary my-3 px-3 fz-medium fz-xxllarge fw-semi-bold fst-italic px-4">
                                    INICIAR
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </BasePage> 
    </div>
  </template>
  
  <script>
  import BasePage from '@/components/BasePage.vue';
  
  export default {
    name: "HowToplayView",
    components: {
      BasePage
    },
    methods: {
    redirectToPlay() {
      this.$router.push({ name: '/play' });
    },
    redirectToGame() {
      this.$router.push({ name: '/game' });
    },
  },
  }
  </script>