<template>
    <div>
      <BasePage>
        <div class="container-fluid d-flex flex-column justify-content-end flex-grow-1 p-0 position-relative">
          <section class="container">
            <h1 class="text-secondary fw-light text-center fw-lg-light text-lg-start fz-xmedium fz-lg-large fz-xxl-xlarge px-2 pt-4 pt-lg-2 pt-xxl-4 px-lg-0"><i>Veja como ajudamos {{ $route.params.person }}</i></h1>
            <div class="row m-0 p-0 pt-4 pt-lg-2">
                <div class="col-lg-8 col-xxl-9 px-0">
                    <video class="video-story" autoplay controls style="width: 100%; height: 100%;">
                        <source :src="require(`../../../../assets/videos/${$route.params.video}.webm`)" type="video/webm" />
                    </video>
                </div>
                <div class="col-lg-4 col-xxl-3 px-0 pt-xxl-4">
                    <div class="pt-lg-4 pt-5 mt-xxl-5 px-2">
                        <h2 class="px-4 text-secondary text-center text-lg-start fz-xmedium fz-xxl-xmedium mt-1"><i>O Sicredi Pioneira quer <b class="fw-bold">te ajudar também.</b></i></h2>
                        <p class="px-4 fw-light fw-lg-regular text-center text-lg-start fz-smedium pb-2 text-white">
                          <router-link to="/chat" class="fw-bold text-decoration-none text-secondary text-lg-start">
                            Clique aqui
                          </router-link> 
                          para negociar com a <span class="text-secondary fw-bold">Pi</span>, nossa atendente virtual.</p>
                        <div class="d-flex justify-content-center justify-content-lg-start">
                            <img class="w-60 w-lg-60 w-xxl-100 mt-2 position-relative img-assist-video" src="../../../../assets/images/assistent-mb.png" alt="Pi">
                        </div>
                    </div>
                </div>
            </div>
          </section>
        </div>   
      </BasePage> 
    </div>
  </template>
  
  <script>
  import BasePage from '@/components/BasePage.vue';
  
  export default {
    name: "VideoView",
    components: {
      BasePage
    },
    data() {
      return {
      };
    },
    methods: {
    },
  }
  </script>