const questsGame = [
    {
        id: 1,
        quest: 'Pensando em casar? Já é casado, amigado ou ajuntado? Muitos casais não falam sobre dinheiro e muitas vezes acabam enfrentando sérios problemas financeiros logo no início da vida a dois. O que você faz?',
        answer1: { text: 'Conversam sobre isso entre vocês e resolvem juntos.', value: 20 },
        answer2: { text: 'Cada um cuida do seu dinheiro e das suas finanças.', value: 15 },
        answer3: { text: 'Não falamos sobre isso.', value: 10 },
        feedback: 
        '<p class="m-0 fw-semi-bold">Você sabe quais as vantagens de dividir com seu parceiro a sua vida financeira?</p> <p class="m-0">“Estudos demonstram que falar de forma sincera e verdadeira sobre finanças, além de aproximar o casal, ajudam a manter a saúde financeira estável e na conquista de metas.”</p>',
        readTime: 13
    },
    {
        id: 2,
        quest: 'Te ofereceram um novo cartão de crédito, você:',
        answer1: { text: 'Aceita, quanto maior o limite, melhor.', value: 15 },
        answer2: { text: 'Recusa, você já tem um e é o suficiente.', value: 20 },
        answer3: { text: 'Aceita, mas você entrega para alguém usar.', value: 10 },
        feedback: '<p class="m-0">Nas questões financeiras é importante entender o que é desejo e o que é necessidade. Tenha claro o porquê, o motivo, a razão para você ter mais de um cartão.</p>',
        readTime: 5
    },
    {
        id: 3,
        quest: 'Você precisou comprar uma geladeira e a loja te oferece três formas de pagamento. Qual a melhor?',
        answer1: { text: '10% de desconto à vista.', value: 20 },
        answer2: { text: 'até 3x sem juros.', value: 15 },
        answer3: { text: 'até 12x com juros.', value: 10 },
        feedback: '<p class="m-0">Quando você paga à vista aumentam as possibilidades de negociar valores. Geralmente o estabelecimento comercial também sai ganhando com o pagamento à vista. Então se você conseguiu se organizar com uma reserva financeira ou possui dinheiro, aproveite e negocie!</p>',
        readTime: 8
    },
    {
        id: 4,
        quest: 'Gastou mais do que deveria no cartão de crédito esse mês, você prefere:',
        answer1: { text: 'Fazer o pagamento mínimo.', value: 15 },
        answer2: { text: 'Parcelar a fatura.', value: 10 },
        answer3: { text: 'Apertar os gastos durante o mês para pagar toda a fatura.', value: 20 },
        feedback: '<p class="m-0">O pagamento à vista reduz o impacto sobre a renda do mês, e especialmente no cartão de crédito, na qual o pagamento é mensal. Vale a pena reduzir outros gastos para manter o pagamento do cartão em dia!</p>',
        readTime: 6
    },
    {
        id: 5,
        quest: 'Como conquistar equilíbrio financeiro?',
        answer1: { text: 'Criar um planejamento mensal.', value: 20 },
        answer2: { text: 'Não sei o que é equilíbrio financeiro.', value: 10 },
        answer3: { text: 'Estudar mais sobre finanças.', value: 15 },
        feedback: '<p class="m-0">Para você conquistar equilíbrio financeiro e parar de perder dinheiro, é importante buscar autoconhecimento.</p><p class="m-0 fw-semi-bold">Você pode saber mais sobre autoconhecimento e relações com o dinheiro em nosso game:  <a class="text-white" target="_blank" href="https://one.engage.bz/#/loginform?customerId=sicredi&">https://one.engage.bz/#/loginform?customerId=sicredi&</a></p>',
        readTime: 3
    },
    {
        id: 6,
        quest: 'Seu carro estragou e você precisa consertar. Qual a melhor maneira de resolver esse problema?',
        answer1: { text: 'Pago no cartão de crédito.', value: 15 },
        answer2: { text: 'Faço um empréstimo.', value: 10 },
        answer3: { text: 'Pago à vista e peço desconto.', value: 20 },
        feedback: '<p class="m-0">Faça um inventário das suas dívidas e planeje o pagamento. Imprevistos acontecem, podemos aprender sempre e adicionar uma reserva no orçamento justamente para prevenção desses imprevistos.</p>',
        readTime: 7
    },
    {
        id: 7,
        quest: 'Você está precisando de um novo automóvel, qual forma de pagamento você pretende utilizar?',
        answer1: { text: 'Financiamento.', value: 10 },
        answer2: { text: 'Pego minhas economias e pago a vista.', value: 15 },
        answer3: { text: 'Espero para juntar dinheiro para a compra.', value: 20 },
        feedback: '<p class="m-0 fw-semi-bold">Quer decisões financeiras mais conscientes? Esta dica ira te ajudar:</p> <p class="m-0"><a class="text-white" target="_blank" href="https://www.sicredipioneira.com.br/educacaofinanceira/para-voce/dicas/de-que-maneira-podemos-tomar-decisoes-financeiras-mais-conscientes">https://www.sicredipioneira.com.br/educacaofinanceira/para-voce/dicas/de-que-maneira-podemos-tomar-decisoes-financeiras-mais-conscientes</a></p>',
        readTime: 6
    },
    {
        id: 8,
        quest: 'Você teve um acidente de carro e estava coberto pelo seguro. Você acha melhor:',
        answer1: { text: 'Pegar o dinheiro e fazer umas compras.', value: 10 },
        answer2: { text: 'Pegar o dinheiro do seguro e quitar o carro.', value: 20 },
        answer3: { text: 'Guardar o dinheiro e deixar render.', value: 15 },
        feedback: '<p class="m-0">O seguro é importante para auxiliar nos momentos imprevistos. Não economize tempo para olhar suas contas e quitar o que é preciso.</p>',
        readTime: 6
    },
    {
        id: 9,
        quest: 'Você comprou um novo carro e com a pandemia não conseguiu pagar as parcelas, qual a melhor saída?',
        answer1: { text: 'Continuar pagando o que é possível.', value: 10 },
        answer2: { text: 'Fazer um empréstimo do valor total do carro para quitar.', value: 20 },
        answer3: { text: 'Fazer um empréstimo sobre a dívida acumulada.', value: 15 },
        feedback: '<p class="m-0 fw-semi-bold">Você tem dúvidas sobre finanças? A Sicredi Pioneira sempre tem dicas para te ajudar:</p><ul><li>Liste todas as suas dívidas.</li><li>Faça um plano para quitar uma de cada vez.</li><li>Não se julgue, nem se condene por ter dívidas e o importante é manter firme a decisão de botar suas contas em dia.</li></ul>',
        readTime: 8
    },
    {
        id: 10,
        quest: 'Você financiou uma casa nova e ela precisa de algumas melhorias, como você planeja realizar?',
        answer1: { text: 'Fazer um planejamento das necessidades e aos poucos concluir a reforma.', value: 20 },
        answer2: { text: 'Fazer tudo e parcelar no cartão.', value: 15 },
        answer3: { text: 'Fazer um segundo empréstimo para as reformas.', value: 10 },
        feedback: '<p class="m-0 fw-semi-bold">Trazemos para você algumas dicas de como montar um orçamento familiar:</p><ul><li>Antes de iniciar anote todos os gastos sem exceção.</li><li>Saiba o ganho real, líquido mensal, considere os descontos.</li><li>Saiba quanto a família gasta com DESPESAS FIXAS (todo mês: luz, água, supermercado...). E com DESPESAS VARIÁVEIS (ocorre às vezes como IPVA, IPTU, consertos, etc)</li></ul>',
        readTime: 7
    },
    {
        id: 11,
        quest: 'Ocorreu um temporal na sua cidade e você teve danos estruturais na sua casa. Qual é a melhor solução?',
        answer1: { text: 'Apostar em jogos de sorte.', value: 10 },
        answer2: { text: 'Fazer uma vakinha online.', value: 15 },
        answer3: { text: 'Fazer um empréstimo equilibrando com as suas contas atuais.', value: 20 },
        feedback: '<p class="m-0">O financiamento, geralmente de longo prazo, é utilizado para aquisição de um bem ou serviço específico. Através dele é possível realizar muitos objetivos, como a reforma da casa, por exemplo.</p>',
        readTime: 8
    },
    {
        id: 12,
        quest: 'A família vai crescer e você precisa ampliar a casa, qual a melhor opção?',
        answer1: { text: 'Criar um planejamento financeiro.', value: 20 },
        answer2: { text: 'Pegar um cartão de crédito extra para efetuar as compras.', value: 15 },
        answer3: { text: 'Comprar no cartão de um parente/amigo.', value: 10 },
        feedback: '<p class="m-0 fw-semi-bold">Trazemos para você algumas dicas de como montar um orçamento familiar:</p><ul><li>Utilize ferramentas de controle (planilha mensal, aplicativos, blocos de anotações...).</li><li>Escolha alguém para ser o organizador (quem na família tem mais facilidade com números e gosta de organizar).</li><li>Conheça seus ganhos e suas despesas.</li></ul>',
        readTime: 6
    },
    {
        id: 13,
        quest: 'As contas acumularam na pandemia e você está no vermelho, qual a melhor opção para você se organizar?',
        answer1: { text: 'Conversar com o meu gerente para me organizar com o banco.', value: 15 },
        answer2: { text: 'Fazer um curso de organização financeira gratuito e botar em prática.', value: 20 },
        answer3: { text: 'Pagar no tempo em que eu consigo.', value: 10 },
        feedback: '<p class="m-0 fw-semi-bold">Você tem dificuldade em se organizar? Anote aí algumas dicas para facilitar a sua organização:</p><ul><li>Comece fazendo um diagnóstico financeiro do seu momento atual.</li><li>Por no mínimo um mês, anote tudo o que você gasta, independentemente do valor e forma de pagamento.</li><li>Faça anotações por tipo de despesa, o que facilita a visualização diariamente.</li></ul>',
        readTime: 7
    },
    {
        id: 14,
        quest: 'Você precisou comprar o anel de noivado, a loja te deu três formas de pagamento. Qual a melhor?',
        answer1: { text: '15% de desconto à vista.', value: 20 },
        answer2: { text: 'até 3x sem juros.', value: 15 },
        answer3: { text: 'até 12x com juros.', value: 10 },
        feedback: '<p class="m-0">Realizar uma compra para a conquista de bens, é uma ótima atividade, desde que seja feita com organização e consciência dos seus limites. Lembre-se que, a vista, você sempre terá a melhor possibilidade de negociação. </p>',
        readTime: 7
    },
    {
        id: 15,
        quest: 'Com o início do novo ano, vem os compromissos de pagamentos de IPVA e IPTU. Como você se organiza para esses gastos?',
        answer1: { text: 'Guardar dinheiro durante o ano e paga a vista com 10% de desconto.', value: 20 },
        answer2: { text: 'Paga parcelado em 10x e fica com essa conta durante o ano.', value: 15 },
        answer3: { text: 'Deixo atrasar se necessário e pago quando posso.', value: 10 },
        feedback: '<p class="m-0 fw-semi-bold">Acesse as soluções financeiras disponíveis para estes compromissos no artigo:</p><p class="m-0"><a class="text-white" target="_blank" href="https://www.sicredipioneira.com.br/educacaofinanceira/para-voce/artigos/solucoes-financeiras-que-serao-suas-aliadas-no-orcamento-pessoal-e-familiar">https://www.sicredipioneira.com.br/educacaofinanceira/para-voce/artigos/solucoes-financeiras-que-serao-suas-aliadas-no-orcamento-pessoal-e-familiar</a></p>',
        readTime: 9
    },
    {
        id: 16,
        quest: 'Você será mãe/pai e terá que fazer compras para o bebê, você prefere:',
        answer1: { text: 'Comprar tudo e parcelar para não faltar nada.', value: 10 },
        answer2: { text: 'Comprar aos poucos e pesquisar descontos.', value: 20 },
        answer3: { text: 'Comprar de acordo com as necessidades da criança.', value: 15 },
        feedback: '<p class="m-0">Faça um exercício sincero e escreva quais foram as melhores e as piores decisões financeiras. Isso vai ampliar teu autoconhecimento e te ajudar a planejar as compras do seu bebe!</p><p class="m-0">Para conhecer mais, acesse o site de educação financeira - <a class="text-white" target="_blank" href="https://sicredipioneira.com.br/educacaofinanceira/">https://sicredipioneira.com.br/educacaofinanceira/</a></p>',
        readTime: 7
    },
];
export default questsGame;