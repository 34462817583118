<template>
    <div>
      <BasePage>
        <div class="position-relative d-flex flex-column align-items-center px-lg-5 py-2 py-xxl-5 mb-xxl-5">
    
            <div id="modal-quest" class="d-none position-fixed text-center semitransparent-container--extra-dark-green-solid p-3 text-white" ref="modalQuest">
                    <span class="fw-bold fz-medium">Deseja confirmar essa resposta?</span>
                    <div class="d-flex w-100 justify-content-center">
                        <button  @click="confirmAnswer" class="btn btn-secondary my-3 px-3 fz-medium fz-xxllarge fw-semi-bold fst-italic px-4 me-3">Sim</button>
                        <button @click="cancelAnswer" class="btn btn-secondary my-3 px-3 fz-medium fz-xxllarge fw-semi-bold fst-italic px-4">Não</button>
                    </div>
            </div>
    
            <img class="position-absolute logo-sicreditos img-game" src="../../../../assets/images/logo-game.png"
                alt="Logo Sicréditos">
    
            <div class="d-flex w-100 flex-column align-items-center position-relative semitransparent-container--light-green px-lg-5 container-questions">
                <div class="px-2 px-lg-5 w-100 d-flex flex-row justify-content-end">
                    <div class=" mt-5 header-game-100 w-lg-90 d-flex flex-row justify-content-between">
                        <div class="d-flex flex-row align-items-center">
                            <img id="clock" style="width: 50px; height: 50px" class="me-3"
                                src="../../../../assets/images/clock.png" alt="Tempo restante" />
                            <div class="text-extra-dark-green d-flex flex-column fw-bold lh-1">
                                <span id="seconds" class="fz-xlarge fst-italic">{{ seconds }}</span>
                                <span class="fz-medium">Segundos</span>
                            </div>
                        </div>
                        <div class="d-flex flex-row">
                            <img id="coins-icon" style="width: 50px; height: 50px" class="me-3"
                                src="../../../../assets/images/coin.png" alt="Sicréditos" />
                            <div class="d-flex flex-column text-extra-dark-green lh-1 fw-bold">
                                <span id="coins" class="fz-xlarge fst-italic">{{ coins }}</span>
                                <span class="fz-medium">Sicréditos</span>
                            </div>
                        </div>
                    </div>
                </div>
    
                <div class="px-3 px-lg-5 pb-xxl-2 w-100 text-white">
                    <div class="ps-lg-3 position-relative d-flex flex-row align-items-center">
                        <img src="../../../../assets/images/question.png" alt="Pergunta"
                        class="img-quest d-none d-lg-block position-absolute mt-4 mt-xxl-5">
                        <div class="semitransparent-container--extra-dark-green w-100 px-3 pt-3 p-xxl-3 ps-lg-0 mt-3 mt-xxl-5 d-flex flex-row justify-content-center justify-content-lg-end"
                            style="margin-top: -30px">
                            <p id="quest" class="fw-semi-bold fz-medium lh-1 fst-italic w-lg-90">
                                {{ quests[questsID[currentQuest]].quest }}
                            </p>
                        </div>
                    </div>
    
                    <div class="mt-xxl-4 pt-3 answers-container text-white">
                        <ul class="answers-list m-0 p-0">
                            <li v-for="answer in [1, 2, 3]"
                                :key="answer"
                                :id="'answer' + answer"
                                @click="selectAnswer('answer' + answer)" 
                                class="answerOpt list-unstyled mb-1 mb-xxl-2 d-flex flex-row justify-content-end align-items-center w-100">
                                <span style="width: 20px" class="fz-xlarge fst-italic fw-bold position me-3">{{ answer }}</span>
                                <span :id="'txt-answer' + answer" class="semitransparent-container--extra-dark-green w-90 cursor-pointer p-3 fw-medium text-start">
                                    {{ quests[questsID[currentQuest]]['answer' + answer].text }}
                                </span>
                            </li>
                        </ul>
                    </div>
    
                    <div class="d-flex">
                        <div
                            class="w-100 text-center position-relative d-flex flex-column justicy-content-center align-items-center fst-italic fw-bold lh-lg">
                            <span id="questnumber" class="">{{ currentQuest + 1 }} de 4</span>
                            <span  class="position-lg-absolute fz-medium d-none" style="right: 0px; cursor: pointer">
                                Próxima pergunta
                                <a id="final" href="./final-game"  class="d-none"></a>
                                <img src="../../../../assets/images/next.png" alt="Próximo"
                                    style="height: 30px; width: 30px">
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </BasePage> 
    </div>
</template>
  
<script>
import BasePage from '@/components/BasePage.vue';
import questGame from '@/assets/js/quests-game'
import clockAudio from '../../../../assets/soundEffects/clock.mp3';
import coinsAudio from '../../../../assets/soundEffects/coins.mp3';

export default {
    name: "PLayGameView",
    components: {
      BasePage
    },
    data() {
        return {
            quests: questGame,
            coins: 75,
            questsID: [],
            coinsQuest: 0,
            currentQuest: 0,
            questTime: [30, 20, 15, 10],
            currentTimer: null,
            tiktak: new Audio(clockAudio),
            coinsSound: new Audio(coinsAudio),
            seconds: 30
        };
    },
    mounted() {
        this.setQuest();
    },
    created() {
        this.sortQuests();
    },
    methods: {
        sortQuests() {
            const maxQuestions = 9;
            const questionsToSelect = 4;
            const selectedQuestions = new Set();
            
            while (selectedQuestions.size < questionsToSelect) {
                const questSort = Math.floor(Math.random() * maxQuestions);
                if (!selectedQuestions.has(questSort)) {
                    selectedQuestions.add(questSort);
                }
            }

            this.questsID = Array.from(selectedQuestions);
        },
        setQuest() {
            this.tiktak.pause();  
            document.querySelectorAll(".answers-container")[0].classList.remove("active-ans");
            document.getElementById("clock").classList.remove("clock-anime");
            document.getElementById("coins-icon").classList.remove("coins-anime");
            this.timer();
            this.seconds = this.questTime[this.currentQuest];
            let answers = document.getElementsByClassName("answerOpt");
            for (var i = 0; i < answers.length; i++) {
                answers[i].classList.remove("active");
            }
            this.coinsQuest = 0;
        },
        nextQuest(timeOut) {
            if (this.coinsQuest != 0 || timeOut) {
            this.currentQuest++;
            this.coins += this.coinsQuest;
            if (this.currentQuest < 4) {
                this.setQuest();
            } else {
                clearInterval(this.currentTimer);
                this.tiktak.pause();
                document.getElementById("clock").classList.remove("clock-anime");
                this.$router.push({ name: 'EndGame', params: { valorTotal: this.coins } });
            }
            } else if (this.coinsQuest == 0) {
                console.log("error");
            }
        },
        timer() {
            var seconds = this.quests[this.questsID[this.currentQuest]].readTime + this.questTime[this.currentQuest];
            if (this.currentTimer != null) {
                clearInterval(this.currentTimer);
            }
            this.seconds = seconds;
            this.currentTimer = setInterval(() => {
            if (seconds == 0) {
                this.nextQuest(1);
            } else {
                seconds--;

            if (seconds < this.questTime[this.currentQuest]) {
                this.coins--;
                this.seconds = seconds;
                // this.coinsQuest = this.coins;
            }

            if (seconds == this.questTime[this.currentQuest]) {
                document.getElementById("coins-icon").classList.add("coins-anime");
                document.getElementsByClassName("answers-container")[0].classList.add("active-ans");
                this.coinsSound.play();
            }
            if (seconds == 5) {
                this.tiktak.play();
                this.tiktak.loop = true;
                document.getElementById("clock").classList.add("clock-anime");
            }
            }
        }, 1000);
        },
        selectAnswer(id) {
            let answers = document.getElementsByClassName("answerOpt");
            for (var i = 0; i < answers.length; i++) {
                answers[i].classList.remove("active");
            }
            const selected = document.getElementById(id);
            selected.classList.add("active");
            if (id == "answer1") {
                this.coinsQuest = this.quests[this.questsID[this.currentQuest]].answer1.value;
            } else if (id == "answer2") {
                this.coinsQuest = this.quests[this.questsID[this.currentQuest]].answer2.value;
            } else if (id == "answer3") {
                this.coinsQuest = this.quests[this.questsID[this.currentQuest]].answer3.value;
            }
            document.getElementById("modal-quest").classList.remove("d-none");
            },
        confirmAnswer() {
            this.nextQuest();
            this.$refs.modalQuest.classList.add('d-none');
        },
        cancelAnswer() {
            this.$refs.modalQuest.classList.add('d-none');
        }
    },
}
</script>